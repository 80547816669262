<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <div style="float:left;"><img src="/image/common/top_title_wood_bg02.png" /></div>
              <div style="float:left;margin-top:-3px;"><h3>Study Result All</h3></div>
              <div style="float:left;"><img src="/image/common/top_title_wood_bg04.png" /></div>
            </div>
          </div>
          <div class="studybox_h4_box">
            <h4>학습결과 전체보기</h4>
          </div>
          <section class="study_result_area" style="padding:0px 0px 20px 0px;">
            <div class="mypage_scroll_content">
              <section class="study_result_box">
                <!-- content -->
                  <div style="overflow:auto;width:100%;">
                    <table class="board_typeB" summary="학습결과 리스트" >
                    <colgroup>
                    </colgroup>
                    <thead>
                      <tr>
                        <th class="study_result_title00"><p>Book Title</p></th>
                        <th class="study_result_title01"><p>예습</p></th>
                        <th class="study_result_title02"><p>화상수업</p></th>
                        <th class="study_result_title03"><p>복습</p></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(result, key) in learningResults"
                        :key="`result-${key}`"
                      >
                        <td>
                          <p v-text="result.content_title" class="result-book-title"></p>
                        </td>
                        <td>
                          <div style="display:inline-block;" v-if="result.watch_video">
                            <input type="checkbox" checked v-if="result.watch_video.status === 'complete'" />
                            <input type="checkbox" v-else />
                            <label><span></span></label>영상보기
                          </div>
                          <div style="display:inline-block;" v-if="result.translation">
                            <input type="checkbox" checked v-if="result.translation.status === 'complete'" />
                            <input type="checkbox" v-else />
                            <label><span></span></label>직독직해
                          </div>
                          <div style="display:inline-block;" v-if="result.recording">
                            <input type="checkbox" checked v-if="result.recording.status === 'complete'" />
                            <input type="checkbox" v-else />
                            <label><span></span></label>듣고녹음
                          </div>
                        </td>
                        <td>
                          <input type="checkbox"/>
                          <label><span></span></label>화상녹음
                        </td>
                        <td>
                          <div class="study_result_quiz_area">
                            퀴즈
                            <div class="study_result_quiz_num01">{{ result.quiz.correct.length }}</div>
                            <div style="display:inline-block;">
                              <img src="/image/sub/study_result_slash.png" alt="슬래쉬 아이콘" style="vertical-align:middle;"></div>
                            <div class="study_result_quiz_num01">{{ result.quiz.contents.length }}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'study-room' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import StudyRoom from '@/models/StudyRoom'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'StudyRoom.StudyResult',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      loading: false,
      learningResults: []
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loading = true
      const response = await StudyRoom.getLearningResult()
      this.learningResults = response.data.learningResults
      this.loading = false
    }
  }
}

</script>

<style scoped>
</style>
